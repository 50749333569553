<template>
  <!-- <div class="bg-white">
    <img src="../assets/images/logo-lotuss.png" class="mx-auto h-20" />
  </div> -->
  <div id="footer" class="z-40 fixed pt-1 bottom-0 left-0 right-0 w-full flex">
    <div
      class="bg-gradient-to-r from-yellow-700 via-yellow-500 to-yellow-700 w-full flex px-2"
    >
      <div class="flex flex-grow w-1/5 items-center justify-center py-2">
        <router-link to="/submit">
          <div class="text-center w-full">
            <div class="text-blue-500 text-xl hover:text-blue-500">
              <i class="bi bi-camera"></i>
            </div>

            <div class="text-xs text-blue-500 rounded-full pt-0">
              ส่งใบเสร็จ
            </div>
          </div>
        </router-link>
      </div>
      <div class="flex flex-grow w-1/5 items-center justify-center py-2">
        <router-link to="/condition">
          <div class="text-center w-full">
            <div class="text-blue-500 text-xl hover:text-blue-500">
              <i class="bi bi-blockquote-left"></i>
            </div>
            <div class="text-xs text-blue-500 rounded-full pt-0">
              เงื่อนไขกติกา
            </div>
          </div>
        </router-link>
      </div>
      <div class="flex flex-grow w-1/5 items-center justify-center py-2">
        <router-link
          to="/"
          class="bg-gradient-to-b from-blue-500 to-blue-500 rounded-full mt-n4 px-4 py-3 border-0 border-gray-50 shadow-lg"
          style="margin-top: -40px"
        >
          <div class="text-center w-full">
            <div class="text-white text-2xl">
              <i class="bi bi-house"></i>
            </div>
          </div>
        </router-link>
      </div>
      <div class="flex flex-grow w-1/5 items-center justify-center py-2">
        <router-link to="/redeem">
          <div class="text-center w-full">
            <div class="text-blue-500 text-xl hover:text-blue-500">
              <i class="bi bi-gift"></i>
            </div>
            <div class="text-xs text-blue-500 rounded-full pt-0">
              แลกของรางวัล
            </div>
          </div>
        </router-link>
      </div>
      <div class="flex flex-grow w-1/5 items-center justify-center py-2">
        <router-link to="/history">
          <div class="text-center w-full">
            <div class="text-blue-500 text-xl hover:text-blue-500">
              <i class="bi bi-person-workspace"></i>
            </div>
            <div class="text-xs text-blue-500 rounded-full pt-0">
              ประวัติการส่ง
            </div>
          </div></router-link
        >
      </div>
    </div>
  </div>
</template>
<script>
import { version } from "../../package";
import liff from "@line/liff";

export default {
  data() {
    return {
      drawerOpen: false,
      appVersion: version,
      isDone: false,
      userLineDisplayName: "",
      userLinePictureUrl: "",
    };
  },
  mounted() {},
  async created() {
    await liff.ready;
    if (!liff.isLoggedIn()) {
      liff.login();
    } else {
      const profile = await liff.getProfile();
      this.userLineDisplayName = profile.displayName;
      this.userLinePictureUrl = profile.pictureUrl;
      this.isDone = true;
    }
  },
  computed: {},
  methods: {
    drawerTigger() {
      // console.log("vue-layout:", this.drawerOpen);
      this.drawerOpen = !this.drawerOpen;
    },
  },
};
</script>
<style scoped>
#footer img {
  width: 35px;
  height: 35px;
}
</style>
