<template>
  <div>
    <div class="content w-full xl:w-2/3 lg:w-2/3 md:w-2/3 mx-auto px-5 py-10">
      <Loading
        v-model:active="isLoading"
        :is-full-page="true"
        color="#003E87"
      ></Loading>
      <div v-if="!isLoading">
        <div v-if="!userIsRegister" class="">
          <div class="text-2xl mb-5 text-center font-bold text-blue-500">
            ลงทะเบียน
          </div>
          <div class="relative flex w-full flex-wrap items-stretch mb-3">
            <label class="font-bold mb-2">ชื่อ</label>
            <input
              v-model="inputFName"
              type="text"
              placeholder="กรอกชื่อ"
              class="px-3 py-3 relative border-2 border-blue-500 rounded-lg w-full"
            />
          </div>
          <div class="relative flex w-full flex-wrap items-stretch mb-3">
            <label class="font-bold mb-2">นามสกุล</label>
            <input
              v-model="inputLName"
              type="text"
              placeholder="กรอกนามสกุล"
              class="px-3 py-3 relative border-2 border-blue-500 rounded-lg w-full"
            />
          </div>
          <div class="relative flex w-full flex-wrap items-stretch mb-3">
            <label class="font-bold mb-2">เบอร์โทรศัพท์</label>
            <input
              v-model="inputMobile"
              v-mask="'###-###-####'"
              type="tel"
              placeholder="เบอร์มือถือ 10 หลัก"
              class="px-3 py-3 relative border-2 border-blue-500 rounded-lg w-full"
            />
          </div>
          <!-- <hr class="border border-blue-500" />
          <div class="relative flex w-full flex-wrap items-stretch mb-3 mt-5">
            <label class="font-bold mb-2">ที่อยู่ในการจัดส่ง</label>
            <input
              v-model="inputAddress"
              type="text"
              placeholder="กรอกที่อยู่ในการจัดส่ง"
              class="px-3 py-3 relative border-2 border-blue-500 rounded-lg w-full"
            />
          </div>
          <div class="relative flex w-full flex-wrap items-stretch mb-3">
            <label class="font-bold mb-2">ตำบล</label>
            <input
              v-model="inputTambon"
              type="text"
              placeholder="กรอกตำบล"
              class="px-3 py-3 relative border-2 border-blue-500 rounded-lg w-full"
            />
          </div>
          <div class="relative flex w-full flex-wrap items-stretch mb-3">
            <label class="font-bold mb-2">อำเภอ</label>
            <input
              v-model="inputAmphur"
              type="text"
              placeholder="กรอกอำเภอ"
              class="px-3 py-3 relative border-2 border-blue-500 rounded-lg w-full"
            />
          </div>
          <div class="relative flex w-full flex-wrap items-stretch mb-3">
            <label class="font-bold mb-2">จังหวัด</label>
            <select
              v-model="inputProvince"
              placeholder="กรุณาเลือกจังหวัด"
              class="px-3 py-3 relative border-2 border-blue-500 rounded-lg w-full"
            >
              <option value="">กรุณาเลือกจังหวัด</option>
              <option
                v-for="(s, index) in provinces"
                :key="index"
                :value="s.PRO_NAME"
              >
                {{ s.PRO_NAME }}
              </option>
            </select>
          </div>
          <div class="relative flex w-full flex-wrap items-stretch mb-3">
            <label class="font-bold mb-2">รหัสไปรษณีย์</label>
            <input
              v-model="inputZipcode"
              type="text"
              placeholder="กรอกรหัสไปรษณีย์"
              class="px-3 py-3 relative border-2 border-blue-500 rounded-lg w-full"
            />
          </div> -->
          <div class="mt-10">
            <div class="mt-2">
              <div class="mt-1">
                <label class="inline-flex">
                  <input
                    type="checkbox"
                    class="form-checkbox text-blue-700 h-6 w-6 mt-1"
                    v-model="inputAccept1"
                    @click="showModalAccept1 = true"
                  />
                  <span class="ml-3"
                    >ข้าพเจ้าได้อ่านและยอมรับ
                    <u>ประกาศนโยบายความเป็นส่วนตัวสำหรับกิจกรรม</u> แล้ว</span
                  >
                </label>
              </div>
              <div class="mt-5">
                <label class="inline-flex">
                  <input
                    type="checkbox"
                    class="form-checkbox text-blue-700 h-6 w-6 mt-1"
                    v-model="inputAccept2"
                    @click="showModalAccept2 = true"
                  />
                  <span class="ml-3"
                    >ข้าพเจ้าได้อ่านและยอมรับ
                    <u>เงื่อนไขและกติกาการร่วมกิจกรรม</u> แล้ว</span
                  >
                </label>
              </div>
            </div>
          </div>

          <div class="mt-8 grid gap-4 grid-cols-2">
            <button
              @click="goSubmit"
              class="w-full text-lg rounded-lg bg-gradient-to-b from-blue-700 to-blue-800 text-white py-2"
            >
              ลงทะเบียน
            </button>
            <button
              @click="liffCloseWindow"
              class="w-full text-lg rounded-lg bg-gray-400 text-white py-2"
            >
              ยกเลิก
            </button>
          </div>
        </div>
        <div v-if="userIsRegister">
          <div class="lg:w-1/2 md:w-2/3 sm:w-full mx-auto">
            <div class="p-5 border-4 border-blue-700 bg-white rounded-2xl">
              <div class="flex flex-col text-center">
                <img
                  :src="userLinePictureUrl"
                  alt=""
                  class="rounded-full h-32 w-32 mb-5 mx-auto"
                />

                <div class="text-2xl text-normal font-bold">
                  {{ userLineDisplayName }}
                </div>
                <div class="text-xl text-black mt-2">
                  คุณ{{ custInfo.CUS_FNAME }} {{ custInfo.CUS_LNAME }}
                </div>
                <div class="text-2xl text-blue-500 font-bold">
                  ลงทะเบียนสำเร็จ!
                </div>
                <div class="mt-8 grid gap-4 grid-cols-2">
                  <button
                    @click="goTo('/submit')"
                    class="w-full text-lg rounded-lg bg-gradient-to-b from-blue-700 to-blue-800 text-white py-2"
                  >
                    ส่งใบเสร็จ
                  </button>
                  <button
                    @click="liffCloseWindow"
                    class="w-full text-lg rounded-lg bg-gray-400 text-white py-2"
                  >
                    ปิดหน้าต่าง
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- modal -->
      <div v-if="showModalAccept1">
        <div
          class="z-40 fixed inset-0 bg-black bg-opacity-50 h-screen w-full flex justify-center items-start md:items-center pt-10 md:pt-0"
        >
          <div
            class="z-50 pacity-0 transform relative w-11/12 md:w-1/2 bg-white rounded shadow-lg transition-opacity transition-transform duration-300"
            style="height: 80vh"
          >
            <button
              @click="showModalAccept1 = false"
              class="absolute -top-3 -right-3 bg-yellow-700 hover:bg-yellow-900 text-2xl w-10 h-10 rounded-full focus:outline-none text-white"
            >
              &cross;
            </button>
            <button
              @click="showModalAccept1 = false"
              class="absolute -bottom-5 -left-1 w-full px-10 text-2xl font-bold px-3 py-2 m-1 rounded-b border-b-4 border-l-2 shadow-lg bg-blue-500 border-blue-800 text-white"
            >
              ยอมรับ
            </button>
            <div class="p-5 px-7">
              <h2 class="font-semibold text-blue-500 text-xl font-extrabold">
                {{ headerAccept1 }}
              </h2>
            </div>

            <div
              class="text-sm w-full px-7 pb-20 overflow-x-hidden overflow-y-auto"
              style="height: 65vh"
            >
              <div v-html="contentAccept1"></div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="showModalAccept2">
        <div
          class="z-40 fixed inset-0 bg-black bg-opacity-50 h-screen w-full flex justify-center items-start md:items-center pt-10 md:pt-0"
        >
          <div
            class="z-50 pacity-0 transform relative w-11/12 md:w-1/2 bg-white rounded shadow-lg transition-opacity transition-transform duration-300"
            style="height: 80vh"
          >
            <button
              @click="showModalAccept2 = false"
              class="absolute -top-3 -right-3 bg-yellow-700 hover:bg-yellow-900 text-2xl w-10 h-10 rounded-full focus:outline-none text-white"
            >
              &cross;
            </button>
            <button
              @click="showModalAccept2 = false"
              class="absolute -bottom-5 -left-1 w-full px-10 text-2xl font-bold px-3 py-2 m-1 rounded-b border-b-4 border-l-2 shadow-lg bg-blue-500 border-blue-800 text-white"
            >
              ยอมรับ
            </button>
            <div class="p-5 px-7">
              <h2 class="font-semibold text-blue-500 text-xl font-extrabold">
                {{ headerAccept2 }}
              </h2>
            </div>

            <div
              class="text-sm w-full px-7 pb-20 overflow-x-hidden overflow-y-auto"
              style="height: 65vh"
            >
              <div v-html="contentAccept2"></div>
            </div>
          </div>
        </div>
      </div>
      <!-- end of modal -->
    </div>
  </div>
</template>
<script>
import liff from "@line/liff";
import { UserIcon, DeviceMobileIcon } from "@heroicons/vue/outline";
import router from "../router";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import Service from "../service/service";
function unmask(maskedValue, mask) {
  let defaultTokens = ["#", "X", "S", "A", "a", "!"];
  let unmaskedValue = "";
  let isToken;
  for (let i = 0; i < maskedValue.length; i++) {
    isToken = false;
    for (let j = 0; j < defaultTokens.length; j++) {
      if (mask[i] == defaultTokens[j]) {
        isToken = true;
      }
    }
    if (isToken) {
      unmaskedValue += maskedValue[i];
    }
  }
  return unmaskedValue;
}
export default {
  name: "History",
  data() {
    return {
      isLoading: true,
      userLineId: null,
      userLineDisplayName: null,
      userLinePictureUrl: null,
      userIsRegister: false,
      custInfo: null,

      showModalAccept1: false,
      showModalAccept2: false,

      headerAccept1: null,
      headerAccept2: null,
      contentAccept1: null,
      contentAccept2: null,

      inputFName: "",
      inputLName: "",
      inputMobile: "",
      inputMobileRaw: "",
      inputProvince: "",
      inputAddress: "",
      inputTambon: "",
      inputAmphur: "",
      inputZipcode: "",

      provinces: null,
    };
  },
  components: {
    Loading,
    UserIcon,
    DeviceMobileIcon,
  },
  service: null,
  created() {
    this.service = new Service();
  },
  async mounted() {
    await liff.ready;
    if (!liff.isLoggedIn()) {
      liff.login();
    } else {
      this.isLoading = true;
      const profile = await liff.getProfile();
      this.userLineId = profile.userId;
      this.userLineDisplayName = profile.displayName;
      this.userLinePictureUrl = profile.pictureUrl;
      await this.service.memberIsRegister(this.userLineId).then((data) => {
        console.log(data);
        if (data.isRegisted === 1) {
          this.custInfo = data.custInfo;
          this.userIsRegister = true;
        } else {
          this.userIsRegister = false;
          // this.service.getCampaign().then((data) => {
          //   if (data.campaign.CAMPAIGN_STATUS !== "ONLINE") {
          //     router.push("/off");
          //   }
          // });
        }
      });
      await this.service.getContent("PRIVACY").then((data) => {
        if (data.contentData) {
          this.headerAccept1 = data.contentData.PC_HEADER;
          this.contentAccept1 = data.contentData.PC_BODY;
        }
      });
      await this.service.getContent("CONDITION").then((data) => {
        if (data.contentData) {
          this.headerAccept2 = data.contentData.PC_HEADER;
          this.contentAccept2 = data.contentData.PC_BODY;
        }
      });
      // await this.service.getProvinces().then((data) => {
      //   if (data.data) {
      //     this.provinces = data.data;
      //   }
      // });
      this.isLoading = false;
    }
  },
  methods: {
    goTo(r) {
      window.location.replace(r);
    },
    liffCloseWindow() {
      liff.closeWindow();
    },
    async goSubmit() {
      let isError = false;
      let txtError = "";
      if (this.inputFName === "") {
        isError = true;
        txtError += "<li>กรุณากรอกชื่อ</li>";
      }
      if (this.inputLName === "") {
        isError = true;
        txtError += "<li>กรุณากรอกนามสกุล</li>";
      }
      if (this.inputMobile === "") {
        isError = true;
        txtError += "<li>กรุณากรอกเบอร์มือถือ</li>";
      } else {
        this.inputMobileRaw = unmask(
          this.inputMobile,
          "###-###-####"
        ).toString();

        if (this.inputMobileRaw.length != 10) {
          isError = true;
          txtError += "<li>เบอร์มือถือไม่ถูกต้อง</li>";
        } else {
          await this.service
            .memberDuplicateMobile(this.inputMobileRaw)
            .then((data) => {
              // console.log(data.isDup);
              if (data.isDup === 1) {
                //

                isError = true;
                txtError += "<li>เบอร์นี้มีการลงทะเบียนไว้แล้ว</li>";
              }
            });
        }
      }
      // if (this.inputAddress === "") {
      //   isError = true;
      //   txtError += "<li>กรุณากรอกที่อยู่ในการจัดส่ง</li>";
      // }
      // if (this.inputTambon === "") {
      //   isError = true;
      //   txtError += "<li>กรุณากรอกตำบล</li>";
      // }
      // if (this.inputAmphur === "") {
      //   isError = true;
      //   txtError += "<li>กรุณากรอกอำเภอ</li>";
      // }
      // if (this.inputProvince === "") {
      //   isError = true;
      //   txtError += "<li>กรุณากรอกจังหวัด</li>";
      // }
      // if (this.inputZipcode === "") {
      //   isError = true;
      //   txtError += "<li>กรุณากรอกรหัสไปรษณีย์</li>";
      // }

      if (!this.inputAccept1) {
        isError = true;
        txtError += "<li>กรุณายอมรับนโยบายการคุ้มครองข้อมูล</li>";
      }
      if (!this.inputAccept2) {
        isError = true;
        txtError += "<li>กรุณายอมรับกติกาการร่วมกิจกรรม</li>";
      }
      if (!isError) {
        let formData = new FormData();
        formData.append("lineid", this.userLineId);
        formData.append("fname", this.inputFName);
        formData.append("lname", this.inputLName);
        formData.append("mobile", this.inputMobileRaw);
        formData.append("ref_code", "");
        // formData.append("regAddress", this.inputAddress);
        // formData.append("regTambon", this.inputTambon);
        // formData.append("regAmphur", this.inputAmphur);
        // formData.append("regProvince", this.inputProvince);
        // formData.append("regZipcode", this.inputZipcode);

        formData.append(
          "accept",
          JSON.stringify({
            PRIVACY: this.inputAccept1,
            CONDITION: this.inputAccept2,
          })
        );
        this.service.memberSubmitRegister(formData).then((data) => {
          if (data.isSuccess === 1) {
            this.userIsRegister = true;

            window.location.reload();
          } else {
            this.$swal({
              html: data.msgError,
              title: "พบข้อผิดพลาด",
              icon: "error",
              showCancelButton: true,
              showConfirmButton: false,
              cancelButtonColor: "#b91c1c",

              cancelButtonText: "ตกลง",
            });
          }
        });
      } else {
        this.$swal({
          html: "<ul>" + txtError + "</ul>",
          title: "พบข้อผิดพลาด",
          icon: "error",
          showCancelButton: true,
          showConfirmButton: false,
          cancelButtonColor: "#b91c1c",
          cancelButtonText: "ตกลง",
        });
      }
    },
  },
};
</script>
