<template>
  <div>
    <div class="content w-full xl:w-2/3 lg:w-2/3 md:w-2/3 mx-auto px-5 py-10">
      <loading
        v-model:active="isLoading"
        :is-full-page="fullPage"
        color="#003E87"
      ></loading>
      <div v-if="userIsRegister">
        <div class="lg:w-1/2 md:w-2/3 sm:w-full mx-auto">
          <div class="p-5 border-4 border-blue-700 bg-white rounded-lg">
            <div class="flex flex-col text-center">
              <img
                :src="userLinePictureUrl"
                alt=""
                class="rounded-full h-32 w-32 mb-5 mx-auto"
              />

              <div class="text-2xl text-normal font-bold">
                {{ userLineDisplayName }}
              </div>
              <div class="text-xl text-black">
                คุณ{{ custInfo.CUS_FNAME }} {{ custInfo.CUS_LNAME }}
              </div>

              <div class="text-2xl text-blue-500 font-bold my-5">
                ส่งใบเสร็จสำเร็จ!
              </div>

              <div class="mb-5">
                ขอบคุณที่ร่วมกิจกรรมกับเรา<br />ระบบกำลังดำเนินการตรวจสอบใบเสร็จ
              </div>
              <div class="mt-8 grid gap-4 grid-cols-2">
                <button
                  @click="gotoSubmit"
                  class="w-full text-lg rounded-lg bg-gradient-to-b from-blue-700 to-blue-800 text-white py-2"
                >
                  ส่งใบเสร็จ
                </button>
                <button
                  @click="liffCloseWindow"
                  class="w-full text-lg rounded-lg bg-gray-400 text-white py-2"
                >
                  ยกเลิก
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import liff from "@line/liff";
import Service from "../service/service";
import configs from "../config";
export default {
  name: "Success",
  props: ["shop"],
  data() {
    return {
      userLineId: null,
      userLineDisplayName: null,
      userLinePictureUrl: null,
      userIsRegister: false,
      custInfo: null,
      isLoading: true,
      fullPage: true,
      submitBuyShop: this.shop,
      configs,
    };
  },
  components: {
    Loading,
  },
  service: null,
  created() {
    this.service = new Service();
  },

  async mounted() {
    await liff.ready;
    if (!liff.isLoggedIn()) {
      liff.login();
    } else {
      this.isLoading = true;
      const profile = await liff.getProfile();
      this.userLineId = profile.userId;
      this.userLineDisplayName = profile.displayName;
      this.userLinePictureUrl = profile.pictureUrl;
      await this.service.memberIsRegister(this.userLineId).then((data) => {
        // console.log(data);
        if (data.isRegisted === 1) {
          this.custInfo = data.custInfo;
          this.userIsRegister = true;
        } else {
          this.userIsRegister = false;
        }
      });
      this.isLoading = false;
    }
  },
  methods: {
    gotoSubmit() {
      this.$router.push("/submit");
    },
    liffCloseWindow() {
      liff.closeWindow();
    },
  },
};
</script>
