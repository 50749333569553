import axios from "axios";
import configs from "../config";
export default class Service {
  getReward(f) {
    return axios
      .get(configs.urlBackendApi, {
        params: {
          site: configs.prefix,
          action: configs.prefix.toLowerCase() + "/get-reward",
          filter: f,
        },
      })
      .then((res) => res.data);
  }
  getProvinces() {
    return axios
      .get(configs.urlBackendApi, {
        params: {
          site: configs.prefix,
          action: "00-default/get-province",
        },
      })
      .then((res) => res.data);
  }
  getCampaign() {
    return axios
      .get(configs.urlBackendApi, {
        params: {
          site: configs.prefix,
          action: "00-default/get-campaign",
        },
      })
      .then((res) => res.data);
  }
  getContent(code) {
    return axios
      .get(configs.urlBackendApi, {
        params: {
          site: configs.prefix,
          action: "00-default/get-content",
          code,
        },
      })
      .then((res) => res.data);
  }
  getShop() {
    return axios
      .get(configs.urlBackendApi, {
        params: {
          site: configs.prefix,
          action: "general/get-shop",
        },
      })
      .then((res) => res.data);
  }
  getShopInfo(shopId) {
    return axios
      .get(configs.urlBackendApi, {
        params: {
          site: configs.prefix,
          action: "general/get-shop-info",
          shop_id: shopId,
        },
      })
      .then((res) => res.data);
  }
  getWinnerDate(typeId) {
    return axios
      .get(configs.urlBackendApi, {
        params: {
          site: configs.prefix,
          action: "general/get-winner-date",
          typeId,
        },
      })
      .then((res) => res.data);
  }
  // member
  memberIsRegister(lineId) {
    return axios
      .get(configs.urlBackendApi, {
        params: {
          site: configs.prefix,
          action: configs.prefix.toLowerCase() + "/get-customer-info",
          lineid: lineId,
        },
      })
      .then((res) => res.data);
  }
  memberDuplicateMobile(mobileNo) {
    return axios
      .get(configs.urlBackendApi, {
        params: {
          site: configs.prefix,
          action: "00-default/check-customer-mobile",
          mobileNo,
        },
      })
      .then((res) => res.data);
  }
  memberSubmitRegister(formData) {
    formData.append(
      "action",
      configs.prefix.toLowerCase() + "/submit-register"
    );
    formData.append("site", configs.prefix);
    return axios.post(configs.urlBackendApi, formData).then((res) => res.data);
  }
  memberSubmitBill(formData) {
    formData.append("action", configs.prefix.toLowerCase() + "/submit-bill");
    formData.append("site", configs.prefix);
    return axios
      .post(configs.urlBackendApi, formData)
      .then((res) => res.data.data);
  }
  memberGetRedeemActive(lineId) {
    return axios
      .get(configs.urlBackendApi, {
        params: {
          site: configs.prefix,
          action: configs.prefix.toLowerCase() + "/member-get-redeem-active",
          lineid: lineId,
        },
      })
      .then((res) => res.data);
  }
  memberGetCouponActive(lineId) {
    return axios
      .get(configs.urlBackendApi, {
        params: {
          site: configs.prefix,
          action: configs.prefix.toLowerCase() + "/member-get-coupon-active",
          lineid: lineId,
        },
      })
      .then((res) => res.data);
  }
  memberGetCouponUsed(lineId) {
    return axios
      .get(configs.urlBackendApi, {
        params: {
          site: configs.prefix,
          action: configs.prefix.toLowerCase() + "/member-get-coupon-used",
          lineid: lineId,
        },
      })
      .then((res) => res.data);
  }
  memberReturnCoupon(lineId, serial) {
    return axios
      .get(configs.urlBackendApi, {
        params: {
          site: configs.prefix,
          serial,
          action: configs.prefix.toLowerCase() + "/member-return-coupon",
          lineid: lineId,
        },
      })
      .then((res) => res.data);
  }
  memberProcessRedeem(lineId) {
    return axios
      .get(configs.urlBackendApi, {
        params: {
          site: configs.prefix,
          action: configs.prefix.toLowerCase() + "/member-process-redeem",
          lineid: lineId,
        },
      })
      .then((res) => res.data);
  }
  memberProcessUseCoupon(lineId, serial) {
    return axios
      .get(configs.urlBackendApi, {
        params: {
          site: configs.prefix,
          serial,

          action: configs.prefix.toLowerCase() + "/member-process-use-coupon",
          lineid: lineId,
        },
      })
      .then((res) => res.data);
  }
  memberGetHistory(lineId) {
    return axios
      .get(configs.urlBackendApi, {
        params: {
          site: configs.prefix,
          action: configs.prefix.toLowerCase() + "/get-history",
          line_id: lineId,
        },
      })
      .then((res) => res.data);
  }

  // tool for upload
  awsUpload(formData) {
    formData.append("prefix", configs.prefix.toLowerCase());
    const axiosConfig = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    return axios
      .post(configs.urlUpload, formData, axiosConfig)
      .then((res) => res.data);
  }
}
